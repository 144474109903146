/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

const BookForm = ({ bookTitle }) => {    
    const [oneQuestion, setOneQuestion] = useState("")
    const [secondQuestion, setSecondQuestion] = useState("")
    const [thirdQuestion, setThirdQuestion] = useState("")
    const [fourthQuestion, setFourthQuestion] = useState("")
    const [textSection, setTextSection] = useState("")

    const [loading, setLoading] = useState(false)
    const [sendingText, setSendingText] = useState("送信処理を実行しています...")
    const [sendDone, setSendDone] = useState(false)

    const callFetch = () => {
        fetch("https://us-central1-nov182021.cloudfunctions.net/server/books-questionnaire"/* 'http://localhost:5001/nov182021/us-central1/server/books-questionnaire' 　"https://us-central1-nov182021.cloudfunctions.net/server/books-questionnaire"*/, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                bookTitle: bookTitle, 
                oneQuestion: oneQuestion, 
                secondQuestion: secondQuestion, 
                thirdQuestion: thirdQuestion, 
                fourthQuestion: fourthQuestion,
                textSection: textSection
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log("res.send:data::::", data)
            setSendDone(true)
        })
        .catch(err => alert("エラーが発生しました。ページをリロードして、もう一度トライしてください。", err)) 
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        callFetch()
        setTimeout(() => {
            setSendingText("処理を起動中です。少々お待ちください。")
        }, 3100) 
        setTimeout(() => {
            setSendingText("処理を実行中です...") 
        }, 6000) 
    }
  
 /*    const handleOneQuestion = (e) => {
        setOneQuestion(e.currentTarget.value)
    }

    const handleSecondQuestion = (e) => {
        setSecondQuestion(e.currentTarget.value)
    }

    const handleThirdQuestion = (e) => {
        setThirdQuestion(e.currentTarget.value)
    } 

    const handleFourthQuestion = (e) => {
        setFourthQuestion(e.currentTarget.value)
    }  */

    const handleTextSection = (e) => {
        setTextSection(e.currentTarget.value)
    } 
  
    return (
        <div className="bookform-wrapper">
            <h3>アンケート</h3>
            {sendDone ? 
                <h4>ご協力ありがとうございました。</h4>
            :
                <>
                    <p>本書を読んで、わかりにくかった点やコードの誤り、誤字脱字などありましたら、お知らせいただけると幸いです。</p>
                    <form onSubmit={handleSubmit}>
                  {/*       <input type="radio" onChange={handleOneQuestion} id="one-question" name="question" value="ステート管理ツール"/>
                        <label htmlFor="one-question">Redux、Recoilなどのステート管理ツール</label>
                        <br/>
                        <input type="radio" onChange={handleSecondQuestion} id="second-question" name="question" value="TypeScript"/>
                        <label htmlFor="second-question">TypeScriptについてより深く</label>
                        <br/>
                        <input type="radio" onChange={handleThirdQuestion} id="third-question" name="question" value="他のHooks"/> 
                        <label htmlFor="third-question">useStateやuseEffect以外のReact Hooks（useContext、useCallback、useMemoなど）</label>
                        <br/> 
                        <input type="radio" onChange={handleFourthQuestion} id="fourth-question" name="question" value="GraphQL"/> 
                        <label htmlFor="fourth-question">GraphQL</label>
                        <br/>  */}
                        <textarea name="message" onChange={handleTextSection} rows="5" id="textarea" placeholder="こちらにご記入ください"></textarea>

                        <button type="submit" disabled={loading}>
                            {loading ? 
                                <>{sendingText}</>
                                :
                                <><span>送信</span></>
                            }
                        </button>
                    </form>
                </>
            }
        </div>
    )
}

export default BookForm